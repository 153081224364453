import React, { useState } from "react";
import Container from "../components/Container/Container";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/SEO/SEO";

import InView from "../components/InView";
import { graphql, Link } from "gatsby";

import Image from "gatsby-plugin-sanity-image";

// import React Player
import ReactPlayer from "react-player";

// import Swiper core and required modules
import { Navigation, A11y, Autoplay, Pagination, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

export const query = graphql`
	query OurStoryQuery {
		site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
			defaultImage {
				asset {
					_id
				}
			}
		}
		allSanityOurStory {
			edges {
				node {
					content {
						... on SanityOurStoryMission {
							_key
							_type
							slides {
								title
							}
						}
					}
				}
			}
		}
		sanityOurStory {
			title
			introduction
			introductionSecond
			hero {
				kind
				heroImage {
					...Image
					alt
				}
				heroVideoUrl
			}
			content {
				... on SanityOurStoryHistory {
					_key
					_type
					slides {
						title
						...Image
						alt
						description
						year
					}
				}
				... on SanityOurStoryMission {
					_key
					_type
					slides {
						...Image
						alt
						description
						title
						asset {
							_id
						}
					}
				}
				... on SanityFullBleedImage {
					_key
					_type
					image {
						...Image
						alt
						asset {
							_id
						}
					}
				}
				... on SanityImageTextBlock {
					_key
					_type
					description
					imageDirection
					imageType
					layout
					title
					link
					linkText
					media
					videoUrl
					image {
						...Image
						alt
						asset {
							_id
						}
					}
				}
			}
		}
	}
`;

const OurStory = (props) => {
	const { data, errors } = props;
	const site = (data || {}).site;
	const [loaded, setLoaded] = useState(false);
	const [muted, setMuted] = useState(true);

	const renderMutedButton = () => {
		return (
			<div className="absolute text-sm bottom-4 right-4 ...">
				<button
					onClick={() => setMuted(!muted)}
					className="text-white inline-flex items-center"
				>
					{muted ? (
						<>
							Click to unmute
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="21"
								height="19"
								fill="none"
								viewBox="0 0 21 19"
								className="ml-2"
							>
								<path
									fill="#fff"
									d="M19.886.782L19.231.14a.454.454 0 00-.655 0l-2.728 2.672V.616c0-.497-.55-.787-.93-.497L9.06 4.387H5.74a.603.603 0 00-.592.6v8.308l-4.017 3.937a.433.433 0 000 .642l.655.642c.19.186.466.186.656 0L19.886 1.424c.17-.165.17-.455 0-.642zm-4.968 17.776c.38.29.93 0 .93-.498V7.93L9.23 14.414l5.688 4.144z"
								></path>
							</svg>
						</>
					) : (
						<>
							Click to mute
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="21"
								height="19"
								fill="none"
								viewBox="0 0 21 19"
								className="ml-2"
							>
								<path
									fill="#fff"
									d="M14.4.6L8.7 4.8H5.4c-.3 0-.6.2-.6.6V14c0 .4.3.6.6.6h3.3l5.7 4.2c.4.4 1.1.2 1.1-.5V1.1c0-.7-.7-.9-1.1-.5z"
								></path>
							</svg>
						</>
					)}
				</button>
			</div>
		);
	};

	const MissionSlideContent = ({ item }) => (
		<>
			<div className="md:col-span-7 md:pr-[30px] md:pb-[30px] md:border-r md:border-grey-default box-border">
				<Image
					{...item}
					width={1000}
					height={680}
					alt={item.alt ? item.alt : ""}
				/>
			</div>
			<div className="flex flex-col md:col-span-5 md:pb-[30px] md:pl-[30px]">
				<div className="mt-[100px] mb-[4px] md:mb-0 md:mt-auto">
					<h2 className="font-serif text-lg">{item.description}</h2>
				</div>
			</div>
		</>
	);

	const HistorySlideContent = ({slides}) => {
		return <div className={"history-slide history-slide--layout-" + slides.length}>
			<h3 className="text-[24px] leading-[1.4] lg:text-[36px] lg:leading-[45.86px] font-serif mb-[24px]">
				{slides[0].year}
			</h3>
			<div className="history-slide__item-list flex flex-col">
				{ slides.map((item, index) => {
					return <div data-year={item.year} key={index} className="history-slide-item flex flex-col lg:flex-row gap-[16px] lg:gap-20">
						<div className="history-slide-item__image-col flex-grow">
							<div className="history-slide-item__mobile-title lg:hidden">
								{item.title && <h4 className="font-serif text-[24px]/[1.4] lg:mb-[16px]">{item.title}</h4>}
							</div>
							<div className="history-slide-item__image">
								<Image
									{...item}
									alt={item.alt ? item.alt : ""}
									className={`lazy ` + (loaded && " loaded")}
									onLoad={() => setLoaded(true)}
								/>
							</div>
						</div>
						<div className="history-slide-item__content flex-shrink-0">
							{item.title && <h4 className="history-slide-item__desktop-title font-serif text-[24px]/[1.4] mb-[16px]">{item.title}</h4>}
							<p className="text-base">{item.description}</p>
						</div>
					</div>
				})}
			</div>
		</div>
	};

	function createHistorySlides(slides) {
		var results = [];
		var currentSlide = [];
		var lastYear = -1;

		slides.map((slide) => {
			if(slide.year !== lastYear) {
				if(currentSlide.length > 0) {
					results.push(currentSlide);
				}
				lastYear = slide.year;
				currentSlide = [];
			}

			currentSlide.push(slide);

			// Create a new slide if we're at 3 items
			if(currentSlide.length === 3) {
				results.push(currentSlide);
				currentSlide = [];
			}
		});

		// Add the final slide
		if(currentSlide.length > 0) {
			results.push(currentSlide);
		}

		// Reverse order
		return results;
	}

	const history = data.sanityOurStory.content.find(
		(el) => el._type === "ourStoryHistory"
	).slides;

	const historySlides = createHistorySlides(history);
	const years = [];

	for (let i = 0; i < historySlides.length; i++) {
		if (i === 0) {
			years.push(0);
		} else {
			if (historySlides[i][0].year !== historySlides[i - 1][0].year) {
				years.push(i);
			}
		}
	}

	const pagination = {
		el: ".swiper-pagination",
		clickable: true,
		renderBullet: function (index, className) {
			if (years.includes(index)) {
				return (
					'<span class="' + className + '">' + historySlides[index][0].year + "</span>"
				);
			} else {
				return (
					'<span class="' +
					className +
					'" style="display: none !important;"></span>'
				);
			}
		},
	};

	const mission = data.sanityOurStory.content.find(
		(el) => el._type === "ourStoryMission"
	).slides;

	const missionPagination = {
		el: ".mission-pagination",
		clickable: true,
		renderBullet: function (index, className) {
			return (
				'<span class="' + className + '">' + mission[index].title + "</span>"
			);
		},
	};

	if (errors) {
		return (
			<>
				<GraphQLErrorList errors={errors} />
			</>
		);
	}

	return (
		<>
			<Seo
				title={data.sanityOurStory.title}
				description={data.sanityOurStory.description}
				image={
					data.sanityOurStory.hero.heroImage
						? data.sanityOurStory.hero.heroImage
						: site.defaultImage
				}
			/>
			<Container>
				<div className="absolute hidden md:inline-block left-1/2 transform -translate-x-1/2 container top-20 z-10 text-white">
					<h1 className="font-serif text-[32px] leading-[1.2]">
						{data.sanityOurStory.title}
					</h1>
				</div>

				{data.sanityOurStory.hero.kind === "video" && (
					<div
						className={
							`hero-wrapper video-wrapper lazy ` + (loaded && " loaded")
						}
					>
						<ReactPlayer
							width="100vw"
							height="100vh"
							playing
							playsinline={true}
							loop
							muted
							url={data.sanityOurStory.hero.heroVideoUrl}
							onStart={() => setLoaded(true)}
						/>
					</div>
				)}

				{data.sanityOurStory.hero.kind === "image" && (
					<div className="hero-wrapper">
						<Image
							{...data.sanityOurStory.hero.heroImage}
							width={1600}
							height={880}
							alt={data.sanityOurStory.hero.heroImage.alt ? data.sanityOurStory.hero.heroImage.alt : ""}
							className={`lazy ` + (loaded && " loaded")}
							onLoad={() => setLoaded(true)}
						/>
					</div>
				)}

				{/* Intro Title Block */}
				<InView>
					<div className="border-b border-grey-default box-border pt-5 pb-16 md:py-0">
						<div className="container flex flex-col md:grid md:grid-cols-12 md:mt-[55px] md:mb-[55px]">
							<div className="md:col-span-3">
								<h2>{data.sanityOurStory.title}</h2>
							</div>
							<div className="mt-2 md:mt-0 md:col-span-6">
								<h3 className="font-serif text-lg">
									{data.sanityOurStory.introduction}
									<br />
									{data.sanityOurStory.introductionSecond}
								</h3>
							</div>
						</div>
					</div>
				</InView>

				{/* Flexible Content Block */}
				{data.sanityOurStory.content.length > 0 &&
					data.sanityOurStory.content.map((content, i) => (
						<>
							{content._type === "imageTextBlock" && (
								<InView key={i}>
									<div className="border-b border-grey-default box-border">
										<div className="container flex flex-col md:grid md:grid-cols-12 py-7 md:py-0 md:mt-[30px] gap-[20px] md:gap-[30px]">
											<div
												className={`flex flex-col md:pb-[30px] ${
													content.layout === "video" ? ` md:col-span-4` : ` `
												} ${
													content.layout === "two-thirds"
														? ` md:col-span-5`
														: ` `
												} ${
													content.layout === "one-quarter"
														? ` md:col-span-8`
														: ` `
												}${
													content.imageDirection === "image-right"
														? ` md:pr-[30px] order-1`
														: ` order-2 mt-4 md:mt-0`
												}`}
											>
												<h3 className="max-w-[350px]">{content.title}</h3>
												<div
													className={`mt-3 md:mt-auto ${
														content.layout === "two-thirds"
															? ` max-w-[520px]`
															: ` max-w-[680px]`
													}`}
												>
													<h2 className="font-serif text-lg mb-2">
														{content.description}
													</h2>
													{content.link && (
														<Link
															className="text-sm text-grey-base link"
															to={content.link}
														>
															{content.linkText}
														</Link>
													)}
												</div>
											</div>
											<div
												className={`md:pb-[30px] md:border-grey-default box-border ${
													content.layout === "video" ? ` md:col-span-8` : ` `
												} ${
													content.layout === "two-thirds"
														? ` md:col-span-7`
														: ` `
												} ${
													content.layout === "one-quarter"
														? ` md:col-span-4`
														: ` `
												} ${
													content.imageDirection === "image-right"
														? ` order-2 md:pl-[30px] md:border-l mt-[30px] md:mt-0`
														: ` order-1 md:pr-[30px] md:border-r`
												}`}
											>
												{content.media === "image" &&
													content.imageType === "landscape" && (
														<Image
															{...content.image}
															width={1000}
															height={680}
															alt={content.image.alt ? content.image.alt : ""}
															className={`lazy ` + (loaded && " loaded")}
															onLoad={() => setLoaded(true)}
														/>
													)}
												{content.media === "image" &&
													content.imageType === "portrait" && (
														<Image
															{...content.image}
															width={605}
															height={880}
															alt={content.image.alt ? content.image.alt : ""}
															className={
																`max-w-[75%] md:max-w-[90%] max-h-[880px] w-auto lazy ` +
																(loaded && " loaded")
															}
															onLoad={() => setLoaded(true)}
														/>
													)}
												{content.media === "video" && (
													<div
														className={
															`video-wrapper relative lazy ` +
															(loaded && " loaded")
														}
													>
														<ReactPlayer
															className="react-player"
															width="100%"
															height="100%"
															playing
															playsinline={true}
															loop
															muted={muted}
															config={{
																file: {
																	attributes: {
																		autoPlay: true,
																		muted: true,
																	},
																},
															}}
															url={content.videoUrl}
															onReady={() => setLoaded(true)}
														/>
														{renderMutedButton()}
													</div>
												)}
											</div>
										</div>
									</div>
								</InView>
							)}

							{/* Our History Slider */}
							{content._type === "ourStoryHistory" && (
								<InView key={i}>
									<div className="bg-[#F8F7F5] border-b border-grey-default box-border py-[30px] md:pt-[30px] pb-[60px] px-[24px]">
										<h3>Our history</h3>
										<div className="lg:px-[10%]">
											<Swiper
												className="slider--inspiration slider--history"
												modules={[Navigation, Pagination, A11y]}
												autoHeight={true}
												slidesPerView={1}
												spaceBetween={0}
												navigation
												grabCursor={true}
												pagination={pagination}
												onSlideChange={(s) => {
													setTimeout(() => {
														document
															.querySelectorAll(
																".slider--history .swiper-pagination-bullet-history-active"
															)
															.forEach((el) =>
																el.classList.remove(
																	"swiper-pagination-bullet-history-active"
																)
															);

														let activeBullet = document.querySelector(
															".slider--history .swiper-pagination-bullet-active"
														);
														if (activeBullet.style.display === "none") {
															while (activeBullet.style.display === "none") {
																activeBullet = activeBullet.previousSibling;
															}
															activeBullet.classList.add(
																"swiper-pagination-bullet-history-active"
															);
														}
													});
												}}
											>
												{historySlides.map((item, index) => (
													<SwiperSlide key={index}>
														<HistorySlideContent slides={item} key={index} />
													</SwiperSlide>
												))}
												<div className="swiper-pagination"></div>
											</Swiper>
										</div>
									</div>
								</InView>
							)}

							{content._type === "ourStoryMission" && (
								<InView key={i}>
									<div className="border-b border-grey-default box-border">
										<div className="container relative">
											<Swiper
												className="slider--fade slider--mission"
												modules={[
													Navigation,
													Autoplay,
													Pagination,
													A11y,
													EffectFade,
												]}
												slidesPerView={1}
												navigation
												pagination={missionPagination}
												effect={"fade"}
												loop={true}
												speed={1000}
												autoplay={{
													delay: 13000,
													disableOnInteraction: false,
												}}
											>
												{content.slides.map((item, index) => (
													<SwiperSlide
														item={item}
														key={index}
														className="flex flex-col py-5 md:py-0 md:grid md:grid-cols-12 md:mt-[30px]"
													>
														<MissionSlideContent item={item} key={index} />
													</SwiperSlide>
												))}
												<div className="mission-pagination"></div>
											</Swiper>
										</div>
									</div>
								</InView>
							)}

							{content._type === "fullBleedImage" && (
								<InView key={i}>
									<Image
										{...content.image}
										width={1400}
										height={770}
										alt={content.image.alt}
										className={`lazy ` + (loaded && " loaded")}
										onLoad={() => setLoaded(true)}
									/>
								</InView>
							)}
						</>
					))}
			</Container>
		</>
	);
};

export default OurStory;
